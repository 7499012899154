<template>
    <div>
        <div class="row nk-block-head nk-block-head-sm pb-2">
          <div class="col-12 nk-block-head-content">
            <a-button class="btn-dark btn float-right" @click="showAssetWizard()">Add New Asset</a-button>
            <router-link :to="{ name: 'assetsmanageredit'}"><a-button class="btn-primary btn float-right" style="margin-right:10px;"><em class="icon ni ni-edit"></em> Manage Assets</a-button></router-link>
            <h3 class="nk-block-title page-title">Asset Manager</h3>
            <div class="nk-block-des text-soft">
                <p v-if="showActive === true">You have a total {{activeAssets.length}} active assets.</p>
                <p v-if="showActive === false">You have a total {{assets.length}} inactive assets.</p>
                <a-radio-group v-model="showActive">
                  <a-radio :value="true">Active</a-radio>
                  <a-radio :value="false">Inactive</a-radio>
                </a-radio-group>
            </div>
          </div>
        </div>
        <div class="card" v-if="showActive == true">
          <div class="card-inner">
            <div class="row">
              <div class="col-12">
                <vue-good-table
                  styleClass="vgt-table condensed"
                  :columns="columns"
                  :rows="activeAssets"
                  :search-options="{ enabled: true, placeholder: 'Search'}"
                  @on-cell-click="showAsset"
                >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'createdDate'">
                      {{ $moment(props.row.createdDate).format('H:mm A LL') }}
                    </div>
                    <div v-else-if="props.column.field == 'actions'">
                      <a-dropdown>
                        <a><em class="icon ni ni-more-h" style="fontSize: 20px;"></em></a>
                        <a-menu slot="overlay">
                          <!-- <a-menu-item style="color: black;" @click="showAsset(props)"><em class="icon ni ni-edit"/> Edit</a-menu-item> -->
                          <a-menu-item style="color: red;" @click="deleteAsset(props)"><em class="icon ni ni-cross-circle"/> Retire</a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="showActive == false">
          <div class="card-inner">
            <div class="row">
              <div class="col-12">
                <vue-good-table
                  styleClass="vgt-table condensed"
                  :columns="columns"
                  :rows="assets"
                  @on-cell-click="showAsset"
                >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'createdDate'">
                      {{ $moment(props.row.createdDate).format('H:mm A LL') }}
                    </div>
                    <div v-else-if="props.column.field == 'actions'">
                      <a-dropdown>
                        <a><em class="icon ni ni-more-h" style="fontSize: 20px;"></em></a>
                        <a-menu slot="overlay">
                          <!-- <a-menu-item style="color: black;" @click="showAsset(props)"><em class="icon ni ni-edit"/> Edit</a-menu-item> -->
                          <a-menu-item style="color: red;" @click="deleteAsset(props)"><em class="icon ni ni-cross-circle"/> Retire</a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <a-modal v-model="showWizard" :footer="null" title="Add Asset Wizard" :width="'60%'" :height="'60%'" :dialog-style="{ top: '20px' }">
          <addAsset @assetSaved="getAssets()"/>
        </a-modal>
        <a-drawer :visible="showEditDrawer" :width="600">

        </a-drawer>
    </div>
</template>

<script>
import addAsset from '@/components/assets/addAsset.vue'

export default {
  components: { addAsset },
  data() {
    return {
      columns: [
        { label: 'Asset Name', field: 'name'},
        { label: 'Asset Type', field: 'assetTypeName'},
        { label: 'Assigned To User', field: 'assignedToUser'},
        { label: 'Created Date', field: 'createdDate'},
        { label: 'Created By User', field: 'createdByUser'},
        { label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center'}
      ],
      assets: null,
      showWizard: false,
      showActive: true,
      activeAssets: null,
      showEditDrawer: false
    }
  },
  created() {
    this.getView()
    this.getAssets()
  },
  methods: {
    getView() {
      this.$http.get('/ViewAccess/assets')
      .then(() => {
      })
      .catch(() => {
      })
    },
    showAssetWizard(){
      this.showWizard = true
    },
    getAssets(){ 
        this.showWizard = false
        this.$http.get('/assets/Get_Assets')
        .then((response) => {
            this.getActiveAssets()
            this.assets = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
    },
    getActiveAssets(){ 
        this.showWizard = false
        this.$http.get('/assets/Get_ActiveAssets')
        .then((response) => {
            this.activeAssets = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
    },
    showAsset(params) {
      this.$router.push({name: 'asset', query: {id: params.row.id}})
    },
    deleteAsset(props){
        this.$http.post('/assets/Delete_Asset', props.row)
        .then(() => {
          this.$message.success('Asset Deleted')
          this.getAssets()
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })
    }
  }
}
</script>

<style>

</style>